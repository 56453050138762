import {
  BW_LANGUAGE_EN,
  BW_LANGUAGE_FR
} from 'src/libs/qb-brand-web-components'

const brandConfig = {
  brandId: 'flashpoints',
  name: 'Flashpoints',
  faqEmail: 'fidelite@flashpoints.fr',
  privacyPolicyUrl: 'https://flashpoints.fr/static/assets/privacy.pdf',
  cookiePolicyUrl: 'https://flashpoints.fr/static/assets/cookies.pdf',
  termsUrl: 'https://flashpoints.fr/static/assets/terms.pdf',
  theme: {
    colors: {
      main: '#6C5B7E',
      accent: '#FFFFFF'
    }
  },
  supportedLanguages: [BW_LANGUAGE_EN, BW_LANGUAGE_FR],
  features: {},
  cognito: {
    region: 'eu-central-1',
    poolId: 'eu-central-1_xWy3IfHbQ',
    clientId: '636lorakts9kune9vu6b8thigb',
    poolDomain: 'bwaflashpoints.auth.eu-central-1.amazoncognito.com',
    redirectUri: 'https://flashpoints.fr/login'
  },
  gaTrackingId: '',
  hotjarId: '3419029',
  invertBg: true
}

export default brandConfig
