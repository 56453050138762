import React, { memo } from 'react'

import wandi from '../assets/rewards/wandi.png'
import nandi from '../assets/rewards/nandi.png'
import curryHouse from '../assets/rewards/curry-house.png'
import omerta from '../assets/rewards/omerta.png'
import EarnRewardCards from '../../../components/shared/redeem/EarnRewardCards'

const HowToEarnRewardsWrapper = ({ t }) => {
  const earnOptionsData = [
    {
      label: 'wandi',
      image: wandi,
      link: 'https://www.kooqs.com/store/wandi'
    },
    {
      label: 'nandi',
      image: nandi,
      link: 'https://www.mangeznotez.com/restaurant/nantes/nandi-1#la-carte'
    },
    {
      label: 'curry-house',
      image: curryHouse,
      link: 'http://www.curryhouses.fr/'
    },
    {
      label: 'omerta',
      image: omerta,
      link: 'https://www.privateaser.com/lieu/45989-l-omerta'
    }
  ]

  return <EarnRewardCards data={earnOptionsData} t={t} />
}

export default memo(HowToEarnRewardsWrapper)
